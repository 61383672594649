import React, { useState, useEffect } from "react";

import { roundToTwoDecimals, calculateTotalAgentHours, getCurrentCompletesCount } from "../util/helper";
import { LoaderAndErrorPrompt } from "./higher-order/ComponentWithLoadingAndError";

const PROJECTION_REQUIRED_HOURS = 2;

const metrics = ["completes", "attempts", "cost"];

/**
 * Returns the string type of the completes we're using for the projection
 * @param {number} web_completes
 * @returns {"WEB" | "SMS"} The string type of the completes
 */
function getCurrentCompleteType(web_completes) {
	return web_completes > 0 ? "WEB" : "SMS";
}

let lastManualInput = null;

function CampaignProjection({
	projectionData,
	campaignData,
	getCampaignProjection,
	clearCampaignProjection,
	updateProjection,
	loading,
	error,
	isUpdating,
	updateError,
}) {
	const {
		survey_projection_value,
		survey_duration_days,
		survey_projection_metric,
		agent_hours,
		closed,
		web_closed,
		attempted,
		currentspend,
	} = projectionData;
	const currentSpend = roundToTwoDecimals(currentspend / 100);

	const { costperagenthour, campaigncosts, spendlimit } = campaignData;

	const [selectedMetric, setSelectedMetric] = useState(survey_projection_metric || metrics[0]);
	const [metricTargetValue, setMetricValue] = useState(survey_projection_value || 0);
	const [fieldingWindow, setFieldingWindow] = useState(survey_duration_days || 0);
	const [isDirty, setIsDirty] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	useEffect(() => {
		if (
			selectedMetric !== survey_projection_metric ||
			metricTargetValue !== survey_projection_value ||
			fieldingWindow !== survey_duration_days
		) {
			setIsDirty(true);
		} else {
			setIsDirty(false);
		}
		const [_, eMessage] = validate();
		setErrorMessage(eMessage);
	}, [selectedMetric, metricTargetValue, fieldingWindow]);

	// Store the last manual input value to prevent the costmetric value from being set after switching metrics from cost to other
	useEffect(() => {
		if (selectedMetric !== "cost") {
			lastManualInput = metricTargetValue;
		}
	}, [metricTargetValue]);

	useEffect(() => {
		if (selectedMetric === "cost" && spendlimit > 0) {
			setMetricValue(spendlimit / 100);
		} else {
			setMetricValue(lastManualInput);
		}
	}, [selectedMetric]);

	// Set the initial values after the API call. I've separted these out into individual useEffects, but I don't `think` it's necessary to do so
	useEffect(() => {
		// console.log("overall_target_completes", overall_target_completes);
		if (survey_projection_value) setMetricValue(survey_projection_value);
	}, [survey_projection_value]); // Add the other possible metric values here

	useEffect(() => {
		// console.log("survey_duration_days", survey_duration_days);
		if (survey_duration_days) setFieldingWindow(survey_duration_days);
	}, [survey_duration_days]);

	useEffect(() => {
		// console.log("projected_metric", projected_metric);
		if (survey_projection_metric) setSelectedMetric(survey_projection_metric);
	}, [survey_projection_metric]);

	useEffect(() => {
		getCampaignProjection();
		return () => {
			lastManualInput = null; // Reset the last manual input value when the component unmounts
			clearCampaignProjection();
		};
	}, []);

	const isProjectionEnabled = agent_hours >= PROJECTION_REQUIRED_HOURS;

	const totalAgentHours = calculateTotalAgentHours(selectedMetric, {
		totalTarget: metricTargetValue,
		currentCompletes: getCurrentCompletesCount(closed, web_closed),
		currentAttempts: attempted,
		currentSpend: currentSpend,
		fieldingWindow,
		currentAgentHours: agent_hours,
		costPerAgentHour: costperagenthour,
		campaignCosts: campaigncosts.reduce((acc, { charge }) => acc + charge, 0),
	});

	function onRadioChange(e) {
		setSelectedMetric(metrics[e.target.value]);
	}

	function onMetricValueChange(e) {
		setMetricValue(e.target.value);
	}

	function onFieldingWindowChange(e) {
		setFieldingWindow(e.target.value);
	}

	async function saveProjection() {
		const [isValid, errMsg] = validate();
		if (!isValid) {
			setErrorMessage(errMsg);
			return;
		}
		updateProjection(selectedMetric, metricTargetValue, fieldingWindow);
	}

	function validate() {
		if (isNaN(Number(metricTargetValue)) || metricTargetValue < 1) {
			return [false, "Please enter a valid target value greater than 1."];
		}

		if (!metrics.includes(selectedMetric)) {
			return [false, "Please select a valid metric."];
		}

		if (isNaN(Number(fieldingWindow)) || fieldingWindow < 1) {
			return [false, "Please enter a valid time period."];
		}

		return [true, null];
	}

	let currentValueOfMetric = null;

	switch (selectedMetric) {
		case "completes":
			currentValueOfMetric = getCurrentCompletesCount(closed, web_closed);
			break;
		case "attempts":
			currentValueOfMetric = attempted;
			break;
		case "cost":
			currentValueOfMetric = currentSpend;
			break;
		default:
			throw new Error("Invalid metric.");
	}

	return (
		<div>
			<h3 className="title has-text-centered">Projection</h3>
			<LoaderAndErrorPrompt loading={loading} error={error}>
				{!isProjectionEnabled ? (
					<div className="notification is-warning">
						<p>
							Projections are available only for campaigns that have been launched and texted at least 2
							hours.
						</p>
					</div>
				) : (
					<div className="columns is-desktop">
						<div className="column is-6-desktop is-12-mobile">
							<h4 className="title is-4">Current Projection</h4>
							<div className="box">
								<p className="label">Target Metric</p>
								<div className="radios block">
									{metrics.map((metricName, metricIndex) => (
										<label className="pr-3 is-capitalized" key={metricName}>
											<input
												className="mr-1"
												type="radio"
												name="targetMetric"
												value={metricIndex}
												onChange={onRadioChange}
												checked={selectedMetric === metricName}
											/>
											{metricName}
										</label>
									))}
								</div>
								<div className="block columns is-narrow">
									<div className="column is-4">
										<label className="label is-capitalized">
											Target {selectedMetric}{" "}
											{selectedMetric === "completes"
												? `(${getCurrentCompleteType(web_closed)})`
												: ""}
										</label>
										<div className="field has-addons">
											<div className="control">
												<input
													disabled={selectedMetric === "cost" && spendlimit > 0}
													className="input"
													type="number"
													placeholder={0}
													min={1}
													max={Number.MAX_SAFE_INTEGER}
													value={metricTargetValue}
													onChange={onMetricValueChange}
												/>
											</div>
										</div>
									</div>
									<div className="column">
										<label className="label is-capitalized">
											Current {selectedMetric === "cost" ? "spend" : selectedMetric}
										</label>
										<div className="field has-addons">
											<div className="control">
												<span
													className="button is-static is-capitalized"
													style={{ minWidth: "9rem" }}
												>
													{selectedMetric === "cost"
														? `$${currentValueOfMetric}`
														: currentValueOfMetric}
												</span>
											</div>
										</div>
									</div>
								</div>
								<div className="block">
									<label className="label" htmlFor="projections-time-period-input">
										Remaining Fielding Window
									</label>
									<div className="field has-addons">
										<div className="control">
											<input
												id="projections-time-period-input"
												className="input"
												type="number"
												placeholder={0}
												min={1}
												value={fieldingWindow}
												onChange={onFieldingWindowChange}
											/>
										</div>
										<div className="control">
											<span className="button is-static">
												{fieldingWindow === 1 ? "day" : "days"}
											</span>
										</div>
									</div>
								</div>
								{!!errorMessage && <div className="block notification is-danger">{errorMessage}</div>}
								{!errorMessage && (
									<div className="block notification is-info is-light">
										<p className="label">Total Agent Hours for Today: {totalAgentHours} Hours</p>
									</div>
								)}
								<div className="block">
									<button
										className={`button is-primary ${isUpdating ? "is-loading" : ""}`}
										disabled={!isDirty || errorMessage || isUpdating}
										onClick={saveProjection}
									>
										Save Projection
									</button>
								</div>
								{updateError && (
									<div className="notification is-danger">
										<p>{updateError}</p>
									</div>
								)}
							</div>
						</div>
						<div className="column is-6">
							<h4 className="title is-4">Invoicing</h4>
							<p>Place holder for invoicing data here.</p>
						</div>
					</div>
				)}
			</LoaderAndErrorPrompt>
		</div>
	);
}

export default CampaignProjection;
