export default {
	NOTE: "THIS DATA IS A MIX OF THE `login` ARRAY FROM `retrieveCampaignData`, THE BODY OF THE `getCurrentAgentStatus` RESPONSE, AND SOME MORE DATA",
	success: "Logged In",
	data: {
		login: [
			{
				id: "login-89-2",
				username: "login-89-2",
				campaignName: "8XWVMQyc7D",
				isActive: true,
				outgoing: 8600,
				incoming: 5002,
			},
			{
				id: "login-12-3",
				username: "login-12-3",
				campaignName: "J6TsTCAPIU",
				isActive: true,
				outgoing: 986,
				incoming: 432,
			},
			{
				id: "login-49-5",
				username: "login-49-5",
				campaignName: "axXw7UKLde",
				isActive: true,
				outgoing: 98,
				incoming: 42,
			},
			{
				id: "login-72-6",
				username: "login-72-6",
				campaignName: "hBcKQCNcty",
				isActive: true,
				outgoing: 925,
				incoming: 592,
			},
			{
				id: "login-02-7",
				username: "login-02-7",
				campaignName: "ZFIoikiMew",
				isActive: true,
				outgoing: 986,
				incoming: 5432,
			},
			{
				id: "login-93",
				username: "login-93",
				campaignName: "FEO5eTop7y",
				isActive: true,
				outgoing: 12,
				incoming: 5432,
			},
			{
				id: "login-69-9",
				username: "login-69-9",
				campaignName: "utbMD4VOeu",
				isActive: true,
				outgoing: 5321,
				incoming: 5432,
			},
			{
				id: "login-05-1",
				username: "login-05-1",
				campaignName: "meY5sQQN2F",
				isActive: true,
				outgoing: 0,
				incoming: 0,
			},
			{
				id: "login-74-4",
				username: "login-74-4",
				campaignName: "8swA73Wect",
				isActive: true,
				outgoing: 0,
				incoming: 0,
			},
			{
				id: "login-95-10",
				username: "login-95-10",
				campaignName: "OLkViP7dt3",
				isActive: false,
				outgoing: 0,
				incoming: 0,
			},
			{
				id: "login-60-11",
				username: "login-60-11",
				campaignName: "HuvNpNF2Xt",
				isActive: false,
				outgoing: 0,
				incoming: 0,
			},
			{
				id: "login-01-12",
				username: "login-01-12",
				campaignName: null,
				isActive: false,
				outgoing: 0,
				incoming: 0,
			},
			{
				id: "login-07-13",
				username: "login-07-13",
				campaignName: null,
				isActive: false,
				outgoing: 0,
				incoming: 0,
			},
		],
	},
};
