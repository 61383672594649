import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAllCampaignProjections, getProjections } from "../../redux/Projections/actions";
import { bindActionCreators } from "redux";
import ProjectionsComponent from "./Projections.component";

export function ProjectionsContainer(props) {
	return <ProjectionsComponent {...props} />;
}

function mapStateToProps(state) {
	const organizations = state.vitals.data.data.organizationarray.reduce((acc, org) => {
		acc[org.organizationid] = org;
		return acc;
	}, {});

	return {
		projections: state.projections.globalProjections.data || {},
		loading: state.projections.globalProjections.loading,
		error: state.projections.globalProjections.error,
		organizations,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			getAllCampaignProjections,
		},
		dispatch,
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectionsContainer);
