import { combineReducers } from "redux";
import { loadStateReducer } from "../../util/reusableReducers";
import {
	CAMPAIGN_PROJECTIONS_BY_ID_POST_SUCCESS,
	CAMPAIGN_PROJECTIONS_BY_ID_IS_LOADING,
	CAMPAIGN_PROJECTIONS_BY_ID_HAS_ERROR,
	CAMPAIGN_PROJECTIONS_BY_ID_CLEAR,
	CAMPAIGN_PROJECTIONS_UPDATE_PREFIX,
	CAMPAIGN_PROJECTIONS_UPDATE_ERROR,
	CAMPAIGN_PROJECTIONS_UPDATE_IS_LOADING,
	CAMPAIGN_PROJECTIONS_UPDATE_SUCCESS,
	GLOBAL_PROJECTIONS_GET_SUCCESS,
	GLOBAL_PROJECTIONS_IS_LOADING,
	GLOBAL_PROJECTIONS_HAS_ERROR,
	GLOBAL_PROJECTIONS_POST_SUCCESS,
} from "./types";
// import

const initialState = {
	campaignProjections: {
		data: null,
		loading: false,
		error: false,
	},
	globalProjections: {
		data: null,
		loading: false,
		error: false,
	},
};

export function baseReducer(state, action) {
	switch (action.type) {
		case CAMPAIGN_PROJECTIONS_BY_ID_CLEAR:
			return {
				...state,
				campaignProjections: {
					data: null,
					loading: false,
					error: false,
				},
			};
		case CAMPAIGN_PROJECTIONS_UPDATE_IS_LOADING:
			return {
				...state,
				campaignProjections: {
					...state.campaignProjections,
					updating: true,
					error: false,
				},
			};
		case CAMPAIGN_PROJECTIONS_UPDATE_SUCCESS:
			return {
				...state,
				campaignProjections: {
					...state.campaignProjections,
					updating: false,
					error: false,
				},
			};
		case CAMPAIGN_PROJECTIONS_UPDATE_ERROR:
			return {
				...state,
				campaignProjections: {
					...state.campaignProjections,
					updating: false,
					updateError: action.message,
					error: false,
				},
			};
		default:
			return state;
	}
}

const campaignProjectionsReducer = loadStateReducer({
	[CAMPAIGN_PROJECTIONS_BY_ID_IS_LOADING]: "loading",
	[CAMPAIGN_PROJECTIONS_BY_ID_HAS_ERROR]: "error",
	[CAMPAIGN_PROJECTIONS_BY_ID_POST_SUCCESS]: "success",
});

const globalProjectionsReducer = loadStateReducer({
	[GLOBAL_PROJECTIONS_POST_SUCCESS]: "success",
	[GLOBAL_PROJECTIONS_IS_LOADING]: "loading",
	[GLOBAL_PROJECTIONS_HAS_ERROR]: "error",
});

function finalReducer(state = initialState, action) {
	state = baseReducer(state, action);
	return {
		...state,
		campaignProjections: campaignProjectionsReducer(state.campaignProjections, action),
		globalProjections: globalProjectionsReducer(state.globalProjections, action),
	};
}

export default finalReducer;
