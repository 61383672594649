import { fetchRequest } from "../../middleware/fetchMiddleware";
import {
	CAMPAIGN_PROJECTIONS_BY_ID_PREFIX,
	CAMPAIGN_PROJECTIONS_UPDATE_PREFIX,
	GLOBAL_PROJECTIONS_PREFIX,
} from "./types";

const ENDPOINT_PREFIX = "/projectTracker";

/**
 *
 * @param {Boolean} activeOnly Only fetch active campaigns
 * @returns Promise
 */
export function getAllCampaignProjections(status = "active", organizationId = null) {
	const reqBody = {
		campaignList: [],
	};

	// When `activeOnly` is not set, the endpoint returns all campaigns
	if (status == "active") reqBody.activeOnly = true;
	else if (status == "inactive") reqBody.activeOnly = false;

	const orgIdNum = Number(organizationId);
	if (orgIdNum) reqBody.organizationId = orgIdNum;

	return (dispatch) => {
		return dispatch(fetchRequest(GLOBAL_PROJECTIONS_PREFIX, "POST", `${ENDPOINT_PREFIX}/campaigns`, reqBody));
	};
}

/**
 *
 * @param {Number} campaignId ID of the campaign to fetch projections for
 * @returns Promise
 */
export function getProjectionByCampaignId(campaignId) {
	return (dispatch) => {
		return dispatch(
			fetchRequest(CAMPAIGN_PROJECTIONS_BY_ID_PREFIX, "POST", `${ENDPOINT_PREFIX}/campaigns`, {
				campaignList: [campaignId],
			}),
		);
	};
}

/**
 *
 * @param {Number} campaignId ID of the campaign to update projections for
 * @param {String} projectedMetricName The metric to update
 * @param {Number} projectedMetricValue The value to update the metric to
 * @param {String} fieldingWindow The fielding window to update
 * @returns Promise
 */
export function updateProjectionsByCampaignId(campaignId, projectedMetricName, projectedMetricValue, fieldingWindow) {
	return (dispatch) => {
		return dispatch(
			fetchRequest(
				CAMPAIGN_PROJECTIONS_UPDATE_PREFIX,
				"POST",
				`/campaigns/${campaignId}`,
				{
					ncd: {
						survey_projection_metric: projectedMetricName,
						survey_projection_value: projectedMetricValue,
						survey_duration_days: fieldingWindow,
					},
				},
				{
					campaignId,
				},
			),
		);
	};
}

/**
 * Clear the campaign projections from the store
 */
export function clearCampaignProjection() {
	return (dispatch) => {
		dispatch({
			type: `${CAMPAIGN_PROJECTIONS_BY_ID_PREFIX}_CLEAR`,
		});
	};
}
