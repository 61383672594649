import React from "react";

export const FilterListInput = ({ clearFilterCallback, filterValue, setFilterValueCallback, placeholder }) => {
	return (
		<div className="control has-icons-right">
			<input
				className="input"
				placeholder={placeholder}
				type="text"
				onChange={setFilterValueCallback}
				value={filterValue}
			/>
			{filterValue && (
				<span className="icon is-small is-right">
					<a className="delete" onClick={clearFilterCallback} />
				</span>
			)}
		</div>
	);
};
