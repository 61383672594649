import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * A wrapper component for filtering lists.
 * 
 * @property {array} list the list of items to be filtered ( will be returned in the render prop)
 * @property {string, array} filterKey the key/s that points to the element of the list to filter (optional)
 * @property {function} filterPredicate optional predicate fn applied to all filterKeys to specify the filter target. When not provided, the element is assumed to be able to be filtered directly.
 * @property {function} render render that returns the processed state to child components. Also provides the rendered search bar component. E.G. {list, searchBar }
 * 
 * Example usage:
      <FilterList
        list={campaigns}
        filterKey={["name", "campaignid"]}
        filterPredicate={(elem, query) => elem.querySelector('span')?.textContent.includes(query)}  // filter on the contents of a nested <span> element
        render={(renderProps) => (
          <ChildComponent campaigns={renderProps.list}>
            {renderProps.searchBar}
          </ChildComponent>
        )}
      />
 */
class FilterList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filterValue: "",
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let filterFunction;
		const { filterValue } = prevState;
		const { filterKey, filterPredicate, excludeValue, list } = nextProps;

		/**
		 * A Hight order function that returns a fn() to filter provided items. This is
		 * necessary because we often want to check multiple keys against a single filter
		 * value (eg. does either the name or id contain '123'?).
		 *
		 * @param {string} prop - The key to be used for filtering the item. If null or undefined, the item itself is used.
		 * @returns {function} - A function that takes an item and returns a boolean indicating if the item matches the filter criteria.
		 */
		let generateFilterForProperty = (prop) => (item) => {
			if (filterValue === "") {
				return true;
			}
			// If the item property exists, match it against the filterPredicate...
			if (prop) {
				const target = typeof item[prop] === "object" ? item[prop] : (item[prop] + "").toLocaleLowerCase();
				const result = filterPredicate(target, filterValue.toLocaleLowerCase());
				return excludeValue ? !result : result;
			}
			// ...otherwise, assume the item itself is the target and do a simple case-insensitive match
			else {
				const result = (item + "").toLocaleLowerCase().includes(filterValue.toLocaleLowerCase());
				return excludeValue ? !result : result;
			}
		};

		// Begin the actual filtering process
		// Loop through each property in filterKey and do a search on that property
		if (Array.isArray(filterKey)) {
			filterFunction = (item) => {
				let result = false;
				for (const prop of filterKey) {
					const filterProperty = generateFilterForProperty(prop);
					if (filterProperty(item)) {
						result = true;
						break;
					}
				}
				return result;
			};
		} else {
			filterFunction = generateFilterForProperty(filterKey);
		}

		return {
			list: nextProps.list.filter(filterFunction),
		};
	}

	setFilterValue = (e) => {
		this.setState({
			filterValue: e.target.value,
		});
	};

	clearFilterValue = () => {
		this.setState({
			filterValue: "",
		});
	};

	render() {
		return this.props.render({
			list: this.state.list,
			searchBar: (
				<div className="field" style={this.props.style}>
					<this.props.controlComponent
						placeholder={this.props.placeholder}
						filterValue={this.state.filterValue}
						clearFilterCallback={this.clearFilterValue}
						setFilterValueCallback={this.setFilterValue}
					/>
				</div>
			),
		});
	}
}

FilterList.propTypes = {
	list: PropTypes.array.isRequired,
	render: PropTypes.func.isRequired,
	// optional
	filterKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	filterPredicate: PropTypes.func,
	style: PropTypes.object,
	placeholder: PropTypes.string,
};

FilterList.defaultProps = {
	filterPredicate: (item, query) => String(item).indexOf(query) !== -1, // simple substring check
};

export default FilterList;
