//----------------------------------------------------------------------------------------------------
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logOutPost } from "../../redux/Auth/actions";

import { Link, NavLink } from "react-router-dom";

import ChangePasswordModal from "../modals/ChangePasswordModal";
import { TopBarDropdownMenu } from "./TopBarDropdownMenu";
// DCMTopBar
//
// The top bar contains the Survey 160 logo and the log out button
import {
	faDollarSign,
	faUser,
	faPhone,
	faUmbrella,
	faFileInvoiceDollar,
	faDotCircle,
	faList,
	faBan,
	faTable,
	faWrench,
	faChartSimple,
	faHouse,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// TODO: Will need a map from URL to faIcon
//      EG { "/admin/t2w": faDotCircle, ... }

const pagesIconsMap = {
	"/": faHouse,
	"/admin/t2w": faDotCircle,
	"/admin/users": faUser,
	"/admin/optout-list": faBan,
	"/admin/billing": faDollarSign,
	"/admin/twilio": faPhone,
	"/admin/organizations": faUmbrella,
	"/admin/apply-charge": faFileInvoiceDollar,
	"/admin/panel": faList,
	"/admin/active-campaigns": faTable,
	"/admin/aggregator-settings": faWrench,
	"/admin/projections": faChartSimple,
};

class TopBar extends Component {
	render() {
		const { logMeOut, history, selectedCampaignData, dropdownElements = [] } = this.props;

		return (
			<nav className="navbar is-light" role="navigation" aria-label="main navigation">
				<div className="navbar-brand">
					<div className="navbar-item">
						<h1 className="title">
							<Link style={{ color: "black" }} to="/dashboard">
								<img src="/s160_logo.png" alt="Survey160" />
							</Link>
						</h1>
					</div>
				</div>
				<div className="navbar-end">
					<div className="navbar-item">
						{selectedCampaignData && (
							<h3 className="title">{`${selectedCampaignData.name} (${selectedCampaignData.campaignid})`}</h3>
						)}
					</div>
					{dropdownElements.length !== 0 && (
						<TopBarDropdownMenu title="Admin Tools">
							{dropdownElements.map((page) => (
								<NavLink
									className="navbar-item"
									activeClassName="is-active"
									key={page.to}
									to={"/dashboard" + page.to}
								>
									<FontAwesomeIcon icon={pagesIconsMap[page.to]} />
									&nbsp;
									{page.name}
								</NavLink>
							))}
						</TopBarDropdownMenu>
					)}
					<TopBarDropdownMenu
						title={
							<>
								<FontAwesomeIcon icon={faUser} />
								&nbsp;
							</>
						}
					>
						<div className="navbar-item has-text-weight-bold has-background-light">{this.props.userid}</div>
						<a
							className="navbar-item"
							onClick={() => {
								logMeOut(history);
							}}
							href="#"
						>
							Log Out
						</a>
						<ChangePasswordModal />
					</TopBarDropdownMenu>
				</div>
			</nav>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	selectedCampaignData: state.selectedCampaign.selectedCampaign.selectedCampaignData,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			logMeOut: (history) => logOutPost(history),
		},
		dispatch,
	);

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
