import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import CallCenterDashboard from "./CallCenterDashboard.component";

import { LoaderAndErrorPrompt } from "../higher-order/ComponentWithLoadingAndError";

export function CallCenterDashboardContainer({ loading, error, ...props }) {
	return (
		<LoaderAndErrorPrompt loading={loading} error={error}>
			<CallCenterDashboard {...props} />
		</LoaderAndErrorPrompt>
	);
}

export default connect()(CallCenterDashboardContainer);
