import React, { Component } from "react";

import { Agents } from "./Agents";
import { faCircle, faCopy } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withAsyncCall } from "../higher-order/AsyncWrapper";

import { getAgentsStatus } from "../../redux/Agents/actions";
import AgentLogoutButton from "./AgentLogoutButton";

const statusMap = {
	loggedIn: {
		color: "success",
		icon: faCircle,
	},
	loggedOut: {
		color: "danger",
		icon: faCircle,
	},
};

class AgentsContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const texterURL = process.env.REACT_APP_S160_TEXTER_BASEURL || "";
		const { data, loading, error } = nextProps.agents.agentsStatus;
		if (loading || error || !data) {
			return {
				loginWithActions: [],
			};
		}

		return {
			numberOfLoggedInAgents: nextProps.login.reduce((total, item) => {
				const statusItemExists = data[item.id] !== undefined;
				return statusItemExists ? total + 1 : total;
			}, 0),
			loginWithActions: nextProps.login.map((item) => {
				const statusItemExists = data[item.id] !== undefined;
				const statusItem = data[item.id];

				const col = statusMap[!statusItemExists ? "loggedOut" : "loggedIn"].color;
				const handleLoginClick = (event) => {
					if (texterURL === "") {
						event.preventDefault();
						return;
					}
					navigator.clipboard.writeText(item.password);
					window.open(`${texterURL}/login?userId=${item.id}`, "_blank").focus();
				};

				const handleCopyClick = (text) => (e) => {
					navigator.clipboard.writeText(text);
					const elem = e.currentTarget;
					elem.classList.add("clicked");
					setTimeout(() => {
						elem.classList.remove("clicked");
					}, 1000);
				};

				return {
					...item,
					status: (
						<span className={"has-text-" + col}>
							<FontAwesomeIcon icon={faCircle} size="xs" />
						</span>
					),
					id: (
						<p
							className="copy-text is-clickable"
							style={{
								fontFamily: "monospace",
								position: "relative",
							}}
							onClick={handleCopyClick(item.id)}
							title="Click to copy user ID"
							userid={item.id} // used by <FilterList> to filter by user ID
						>
							<span className="is-inline-block p-2 mr-2">{item.id}</span>
							<FontAwesomeIcon icon={faCopy} />
						</p>
					),
					password: (
						<p
							className="copy-text is-clickable"
							style={{
								fontFamily: "monospace",
								position: "relative",
							}}
							onClick={handleCopyClick(item.password)}
							title="Click to copy password"
						>
							<span className="is-inline-block p-2 mr-2">{item.password}</span>
							<FontAwesomeIcon icon={faCopy} />
						</p>
					),
					login: (
						<a
							className="button"
							onClick={handleLoginClick}
							title="Copy password and launch texter app"
							disabled={texterURL === ""}
						>
							Login
						</a>
					),
					actions: (
						<AgentLogoutButton
							userid={item.id}
							disabled={!statusItemExists ? true : false}
							selectedCampaignid={nextProps.selectedCampaignId}
							refreshCallback={nextProps.changeParams}
						/>
					),
					outgoing: !statusItemExists ? "" : `${statusItem.outgoing} sent`,
					incoming: !statusItemExists ? "" : `${statusItem.incoming} received`,
				};
			}),
		};
	}

	render = () => (
		<Agents
			{...this.props}
			login={this.state.loginWithActions}
			numberOfLoggedInAgents={this.state.numberOfLoggedInAgents}
		/>
	);
}

const mapStateToProps = (state) => ({
	agents: state.agents,
});

const asyncMethod = (campaignid) => (dispatch) => {
	dispatch(getAgentsStatus(campaignid));
};

export default withAsyncCall(AgentsContainer, asyncMethod, mapStateToProps);
