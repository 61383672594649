import React, { useEffect, useState } from "react";

import List, { FilterList, FilterListInput } from "../List";
import { stringComparator } from "../../util/helper";

import data from "./dummyData";

const headers = [
	{
		header: "User ID",
		accessor: "username",
		sort: (a, b) => stringComparator(a.username, b.username),
	},
	{
		header: "Campaign",
		title: "Campaign",
		accessor: "campaignName",
		sort: (a, b) => stringComparator(a.campaignName, b.campaignName),
	},
	{
		header: "Status",
		accessor: "status",
		sort: (a, b) => {
			// Unassigned should always be last
			if (a.status === "Unassigned") return 1;
			if (b.status === "Unassigned") return -1;
			return stringComparator(a.status, b.status);
		},
	},
	{
		header: "Outgoing Last 10m",
		accessor: "outgoing",
	},
	{
		header: "Incoming Last 10m",
		accessor: "incoming",
	},
];

export default function CallCenterDashboardComponent(props) {
	// This mapping might not be needed in the end, depending on the data from the backend
	const listData = data.data.login.map((item) => {
		let status = null,
			incoming = null,
			outgoing = null,
			campaignName = item.campaignName;

		if (item.campaignName) {
			status = item.isActive ? "Logged In" : "Logged Out";
			incoming = item.incoming;
			outgoing = item.outgoing;
		} else {
			status = "Unassigned";
			campaignName = null;
		}

		// We're coverting the 0 to null because <List> component will gray out the row only if the value is null
		return { ...item, status, outgoing, incoming, campaignName };
	});

	const [selectedUserIds, setSelectedUserIds] = useState([]);

	const disableAgentActions = selectedUserIds.length === 0;
	const usePlural = selectedUserIds.length > 1;
	return (
		<div className="container" id="call-center-dashboard">
			<h2 className="block title has-text-centered">Call Center Dashboard (name TBD)</h2>
			<div className="block columns is-centered">
				<div className="column is-12">
					<div className="">
						<FilterList
							list={listData}
							filterKey={["username", "campaignName", "status"]}
							controlComponent={FilterListInput}
							placeholder="Search agents by username, campaign name, or status"
							render={({ searchBar, list: filteredList }) => (
								<>
									<div className="columns is-desktop is-centered">
										<div className="column is-12">{searchBar}</div>
									</div>
									<div className="block columns is-centered">
										<div className="column is-12">
											<button className="button">Create Agent (TODO)</button>
											<button className="button" disabled={disableAgentActions}>
												Remove from Campaign (TODO)
											</button>
											<button className="button" disabled={disableAgentActions}>
												{`Assign Agent${usePlural ? "s" : ""}`} (TODO)
											</button>
											<button className="button" disabled={disableAgentActions}>
												{`Delete Agent${usePlural ? "s" : ""}`} (TODO)
											</button>
										</div>
									</div>
									<List
										checkboxes={true}
										list={filteredList}
										headers={headers}
										onChange={setSelectedUserIds}
									/>
								</>
							)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
