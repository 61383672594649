import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { LoaderAndErrorPrompt } from "../higher-order/ComponentWithLoadingAndError";
import {
	updatePrompt,
	updateScript,
	selectedCampaignFetchData,
	saveCampaignName,
	enterSandboxMode,
	exitSandboxMode,
	getRandomListEntries,
	setCampaignDataUpdated,
	getBillingData,
	clearSelectedCampaign,
} from "../../redux/SelectedCampaign/actions";

import { deleteCampaignPost, setSelectedCampaignIndex } from "../../actions/NAID";
import { toggleSidebar } from "../../actions/sidebar";
import CampaignScreen from "./CampaignScreen";
import { clearErrorMsg } from "../../redux/FetchResponseManager/actions";
import { USER_ROLES, USER_ROLES_LOOKUP_TABLE } from "../../util/constants";
import {
	clearCampaignProjection,
	getProjectionByCampaignId,
	updateProjectionsByCampaignId,
} from "../../redux/Projections/actions";

class CampaignScreenContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {};

		this.updateSelectedCampaignData = this.updateSelectedCampaignData.bind(this);
	}

	componentDidMount() {
		this.updateSelectedCampaignData();
	}

	static getDerivedStateFromProps(props, state) {
		// Because deleting a campaign shifts all others, we need to check the campaignid
		// inside the campaignData

		if (props.selectedCampaignId !== state.previousId || (props.updated && !props.loading && !props.error)) {
			return { previousId: props.selectedCampaignId, wait: false };
		}

		// If the data does not need to be updated
		// set dirty to false
		return null;
	}

	componentDidUpdate() {
		this.updateSelectedCampaignData();
	}

	componentWillUnmount() {
		this.props.clearSelectedCampaign();
	}

	updateSelectedCampaignData() {
		const {
			campaigns,
			selectedCampaignData,
			selectedCampaignId,
			selectedCampaignFetchData,
			getBillingData,
			loading,
			error,
			updated,
		} = this.props;

		const currentIndex = campaigns.findIndex((c) => c.campaignid === selectedCampaignId);

		if (
			(!selectedCampaignData ||
				selectedCampaignId !== this.state.previousId ||
				(campaigns[currentIndex] && selectedCampaignData.campaignid !== selectedCampaignId) ||
				updated) &&
			!loading &&
			!error &&
			!this.state.wait
		) {
			this.setState({ wait: true });
			selectedCampaignFetchData(selectedCampaignId, 5);

			const currentRole = USER_ROLES_LOOKUP_TABLE[this.props.vitalsData.permissionlevel];
			if (currentRole != USER_ROLES.callCenterAdmin && currentRole != USER_ROLES.sender) {
				getBillingData(selectedCampaignId);
			}
		}
	}

	render() {
		return (
			<LoaderAndErrorPrompt {...this.props}>
				{this.props.selectedCampaignData ? <CampaignScreen {...this.props} /> : null}
			</LoaderAndErrorPrompt>
		);
	}
}

const mapStateToProps = (state) => ({
	error: state.selectedCampaign.selectedCampaign.error,
	bwError: state.bandwidth.error,
	loading: state.selectedCampaign.selectedCampaign.loading,
	updated: state.selectedCampaign.selectedCampaign.updated,
	selectedCampaignData: state.selectedCampaign.selectedCampaign.selectedCampaignData,
	userData: state.userData,
	campaignHeaders: state.navigation.data.campaignHeaders,
	projectionData: state.projections.campaignProjections,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			clearSelectedCampaign: () => setSelectedCampaignIndex(null),
			deleteCampaign: (history) => deleteCampaignPost(history),
			clearSelectedCampaign,
			selectedCampaignFetchData,
			getBillingData,
			saveCampaignName,
			updatePrompt,
			updateScript,
			toggleSidebar,
			enterSandboxMode,
			exitSandboxMode,
			getRandomListEntries,
			setCampaignDataUpdated,
			getProjectionByCampaignId,
			updateProjectionsByCampaignId,
			clearCampaignProjection,
			clearErrorMsg,
		},
		dispatch,
	);

export default connect(mapStateToProps, mapDispatchToProps)(CampaignScreenContainer);
