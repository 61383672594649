import React, { useEffect, useState } from "react";
import FilterList from "../generic/FilterList/FilterList";
import { LoaderAndErrorPrompt } from "../higher-order/ComponentWithLoadingAndError";
import { FilterListInput } from "../generic/FilterList/FilterListInput";
import List from "../List";
import { copyToClipboard, roundToTwoDecimals } from "../../util/helper";
import ClipboardFailFallbackModal from "../modals/ClipboardFailFallbackModal";

const headers = [
	{
		header: "ID",
		title: "Campaign ID",
		accessor: "id",
	},
	{
		header: "Campaign",
		title: "Campaign",
		accessor: "campaignname",
	},
	{
		header: "SMS Completes",
		title: "SMS Completes",
		accessor: "closed",
	},
	{
		header: "Web Completes",
		title: "Web Completes",
		accessor: "web_closed",
	},
	{
		header: "Agent Hours",
		title: "Agent Hours",
		accessor: "agent_hours",
	},
	{
		header: "Target Metric",
		title: "Target Metric",
		accessor: "survey_projection_metric",
	},
	{
		header: "Target Metric Value",
		title: "Target Metric Value",
		accessor: "survey_projection_value",
	},
	{
		header: "Hours Projected Today",
		title: "Hours Projected Today",
		accessor: "projected_hours",
	},
];

const filters = [
	{
		value: "active",
		label: "Active",
	},
	{
		value: "inactive",
		label: "Inactive",
	},
	{
		value: "all",
		label: "All",
	},
];

const filterOptions = filters.map((filter, i) => (
	<option key={filter.value} value={filter.value}>
		{filter.label}
	</option>
));

export function ProjectionsContainer({ getAllCampaignProjections, projections, organizations, error, loading }) {
	const [selectedCampaignIds, setSelectedCampaignIds] = useState([]);
	const [activeFilter, setActiveFilter] = useState(filters[0]);
	const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);
	const [exportedText, setExportedText] = useState(null);

	useEffect(() => {
		getAllCampaignProjections();
	}, []);

	useEffect(() => {
		getAllCampaignProjections(activeFilter.value, selectedOrganizationId);
	}, [activeFilter, selectedOrganizationId]);

	function onFilterChange(e) {
		setActiveFilter(filters.find((filter) => filter.value === e.target.value));
	}

	function onSelectOrgChange(e) {
		setSelectedOrganizationId(e.target.value);
	}

	function onSlackExportClick() {
		const selectedCampaigns = selectedCampaignIds.map((id) => ({ ...projections[id], id }));
		const todayNoon = new Date().setHours(12, 0, 0, 0);
		const todayNoonString = new Date(todayNoon).toLocaleTimeString("en-US", {
			hour: "numeric",
			minute: "2-digit",
			timeZone: "America/New_York",
			timeZoneName: "short",
		});

		const slackMessage =
			"For today:\n" +
			selectedCampaigns
				.map((campaign) => {
					const projectedHours = campaign.projected_hours;
					const endTime = new Date(todayNoon + projectedHours * 60 * 60 * 1000);
					const endHour = endTime.getHours();
					const endMinutes = endTime.getMinutes();
					if (endMinutes > 30) {
						endTime.setHours(endHour + 1, 0, 0, 0);
					} else if (endMinutes > 0) {
						endTime.setHours(endHour, 30, 0, 0);
					}

					const endHourString = endTime.toLocaleTimeString("en-US", {
						hour: "numeric",
						minute: "2-digit",
						timeZone: "America/New_York",
						timeZoneName: "short",
					});

					return `\tProject: ${campaign.id} - ${campaign.campaignname}\n\t${campaign.projected_hours} hours from ${todayNoonString} to ${endHourString}`;
				})
				.join("\n\n");
		copyToClipboard(slackMessage, () => setExportedText(slackMessage));
	}

	const campaignIds = Object.keys(projections);
	const list = campaignIds.map((id) => {
		return {
			...projections[id],
			// UGLY!!! But should work
			survey_projection_value:
				projections[id].survey_projection_metric === "cost"
					? `$ ${roundToTwoDecimals(projections[id].currentspend / 100)}`
					: projections[id].survey_projection_value,
			id: id,
			organizationName: organizations[projections[id].organizationid].organizationname,
		};
	});

	const organizationOptions = Object.keys(organizations).map((orgId) => (
		<option key={orgId} value={orgId}>
			{organizations[orgId].organizationname}
		</option>
	));

	return (
		<div className="">
			<h3 className="title has-text-centered is-child">Survey Hours Projections</h3>
			<ClipboardFailFallbackModal
				text={exportedText}
				isOpen={!!exportedText}
				closeModal={() => setExportedText(null)}
			/>
			<FilterList
				list={list}
				filterKey={["id", "campaignname", "organizationName"]}
				controlComponent={FilterListInput}
				placeholder="Search campaigns by project ID, name, or organization name"
				render={({ searchBar, list: filteredList }) => (
					<>
						<div className="columns">
							<div className="column is-8">
								<div className="">{searchBar}</div>
							</div>
						</div>
						<div className="columns is-multiline">
							<div className="field has-addons column is-narrow">
								<div className="control">
									<p className="button is-static">Filter By Status</p>
								</div>
								<div className="control">
									<div className="select">
										<select onChange={onFilterChange} value={activeFilter.value}>
											{filterOptions}
										</select>
									</div>
								</div>
							</div>
							<div className="field has-addons column is-narrow">
								<div className="control">
									<p className="button is-static">Filter By Organization</p>
								</div>
								<div className="control">
									<div className="select">
										<select onChange={onSelectOrgChange} value={selectedOrganizationId}>
											<option value={0}>All</option>
											{organizationOptions}
										</select>
									</div>
								</div>
							</div>
							<div className="field column ">
								<div className="control">
									<button
										onClick={onSlackExportClick}
										disabled={!selectedCampaignIds.length}
										className="button"
									>
										Copy Slack Message to Clipboard
									</button>
								</div>
							</div>
						</div>
						<LoaderAndErrorPrompt loading={loading} error={error}>
							<div className="column is-12 p-0">
								<List
									list={filteredList.sort((a, b) => b.id - a.id)}
									headers={headers}
									checkboxes={true}
									onChange={setSelectedCampaignIds}
								/>
							</div>
						</LoaderAndErrorPrompt>
					</>
				)}
			/>
		</div>
	);
}

export default ProjectionsContainer;
