import { SERVER_URLROOT } from "../../util/constants";

import { setSelectedCampaignIndex, updateCampaignName, updateCampaignStatus } from "../../actions/NAID";
import { createJSONFetchRequest, deepCopy } from "../../util/helper";

import {
	fetchFileRequest,
	fetchRequest,
} from "../../middleware/fetchMiddleware";
import { globalHTTPResponseHandler } from "../FetchResponseManager/actions";
import { addNotification } from "../GlobalNotifications/actions";

export const SELECTED_CAMPAIGN_DATA_FETCH_SUCCESS = "SELECTED_CAMPAIGN_DATA_FETCH_SUCCESS";
export const SELECTED_CAMPAIGN_DATA_HAS_ERROR = "SELECTED_CAMPAIGN_DATA_HAS_ERROR";
export const SELECTED_CAMPAIGN_DATA_IS_LOADING = "SELECTED_CAMPAIGN_DATA_IS_LOADING";
export const SELECTED_CAMPAIGN_DATA_CLEAR = "SELECTED_CAMPAIGN_DATA_CLEAR";
export const UPDATE_CAMPAIGN_DATA_POST_SUCCESS = "UPDATE_CAMPAIGN_DATA_POST_SUCCESS";
export const UPDATE_CAMPAIGN_DATA_IS_LOADING = "UPDATE_CAMPAIGN_DATA_IS_LOADING";
export const UPDATE_CAMPAIGN_DATA_HAS_ERROR = "UPDATE_CAMPAIGN_DATA_HAS_ERROR";
export const ADD_CAMPAIGN_FETCH_SUCCESS = "ADD_CAMPAIGN_FETCH_SUCCESS";
export const AGENT_HOURS_SUCCESS = "AGENT_HOURS_SUCCESS";
export const SET_CAMPAIGN_DATA_UPDATED = "SET_CAMPAIGN_DATA_UPDATED";

export function clearSelectedCampaign() {
	return (dispatch) => {
		dispatch({
			type: SELECTED_CAMPAIGN_DATA_CLEAR,
		});
	};
}

export function selectedCampaignIsLoading(isLoading) {
	return (dispatch) => {
		dispatch({
			type: SELECTED_CAMPAIGN_DATA_IS_LOADING,
			isLoading: isLoading,
		});
	};
}

export function selectedCampaignHasError(hasError) {
	return (dispatch) => {
		dispatch({
			type: SELECTED_CAMPAIGN_DATA_HAS_ERROR,
			hasError: hasError,
		});
	};
}

export function selectedCampaignFetchSuccess(data) {
	return (dispatch) => {
		dispatch({
			type: SELECTED_CAMPAIGN_DATA_FETCH_SUCCESS,
			data: data.data,
		});
	};
}

export function updateCampaignDataFetchSuccess() {
	return (dispatch) => {
		dispatch({
			type: UPDATE_CAMPAIGN_DATA_POST_SUCCESS,
		});
	};
}

export function addCampaignFetchSuccess(data) {
	return (dispatch) => {
		dispatch({
			type: ADD_CAMPAIGN_FETCH_SUCCESS,
			data: data.data,
		});
	};
}

export function selectedCampaignFetchData(campaignid, howmany) {
	return (dispatch, getState) => {
		dispatch(selectedCampaignIsLoading(true));

		const state = getState();

		const params = {
			howmany: howmany || 5,
			campaignid: campaignid,
		};

		createJSONFetchRequest("/retrieveCampaignData", params, "POST", state.auth.jwt)
			.then((response) => {
				dispatch(globalHTTPResponseHandler(response));
				return response;
			})
			.then((response) => response.json())
			.then((data) => {
				// dispatch(selectedCampaignIsLoading(false));
				dispatch(selectedCampaignFetchSuccess(data));
				dispatch(setSelectedCampaignIndex(data.data.campaignid));
				dispatch(clearQuotasData());
			})
			.catch((error) => {
				dispatch(selectedCampaignHasError(true));
			});
	};
}

export function updateCampaignFetchData(campaignid, ncd) {
	return (dispatch, getState) => {
		const modified_on = getState().selectedCampaign.selectedCampaign.selectedCampaignData.modified_on;
		const params = {
			ncd: { ...ncd, modified_on },
		};

		return dispatch(fetchRequest("UPDATE_CAMPAIGN_DATA", "POST", `/campaigns/${campaignid}`, params)).then(
			(response) => {
				// TODO: This is an ugly workaround for now but it shouold work. No response means error
				if (!response) {
					dispatch(
						addNotification({
							type: "UPDATE_CAMPAIGN_DATA_ERROR",
							campaignid,
							visible: true,
						}),
					);
				}
			},
		);
	};
}

/**
 * Update the campaign's name
 * @param {string} newName The new name for the campaign
 * @returns
 */
export function saveCampaignName(newName) {
	return (dispatch, getState) => {
		const state = getState();

		const currentData = state.selectedCampaign.selectedCampaign.selectedCampaignData;
		let newData = {
			name: newName,
		};

		let fetchData = (dispatch) =>
			new Promise((resolve, reject) => {
				dispatch(updateCampaignFetchData(currentData.campaignid, newData));
				resolve();
			});

		fetchData(dispatch).then((data) => {
			dispatch(updateCampaignName(currentData.campaignid, newName));
		});
	};
}

/**
 * Updates the twilio info for the campaign.
 * @param {*} sid Twilio new subaccount sid
 * @param {*} token Twilio new subaccount token
 * @param {*} copilots Number of MS groupings to create on launch
 * @param {*} phones Number of phones to purchase on launch
 */
export function updateCampaignData(sid, token, copilots, phones) {
	return (dispatch, getState) => {
		const state = getState();
		let currentData = state.selectedCampaign.selectedCampaign.selectedCampaignData;

		const newData = {};
		if (sid) newData.subtwiliosid = sid;
		if (token) newData.subtwiliotoken = token;
		if (copilots) {
			newData.numberofcopilot = parseInt(copilots);
		}
		if (phones) {
			newData.pnpercopilot = parseInt(phones);
		}

		dispatch(updateCampaignFetchData(currentData.campaignid, newData));
	};
}

/**
 * Wrapper for updatecampaignData fetch request for bandwidth data.
 * @param {int} numberOfPhones The number of phones to be purchased on activation
 * @param {string} registrationId  The bandwidth registration_id for the campaign
 * @returns {Promise} Propagates the fetch request so that we can chain .then() when
 * calling the function
 */
export function updateCampaignBandwidthData(numberOfPhones, registrationId) {
	return (dispatch, getState) => {
		const state = getState();

		let currentData = state.selectedCampaign.selectedCampaign.selectedCampaignData;

		if (numberOfPhones) {
			numberOfPhones = parseInt(numberOfPhones);
		}

		let newData = {
			pnpercopilot: numberOfPhones,
			bw_registration_id: registrationId,
		};

		return dispatch(updateCampaignFetchData(currentData.campaignid, newData, state.auth.jwt));
	};
}

/**
 * Update the campaign using a new script in newScript.
 * This updates both the local copy and the database.
 * @param {Array} newScript
 */
export function updateScript(newScript, initialConditionals = [], defaultFirstQuestionId) {
	return (dispatch, getState) => {
		const state = getState();
		const campaignid = state.NAID.selectedCampaignIndex;

		const newData = {
			script: newScript,
			defaultfirstquestionid: defaultFirstQuestionId,
			initialconditionals: initialConditionals,
		};

		dispatch(updateCampaignFetchData(campaignid, newData));
	};
}

/**
 *
 * Update the campaign using a new prompt in newPrompt.
 * This updates both the local copy and the database.
 * @param {object} newPrompt the new prompt to replace the old one
 * @returns
 */
export function updatePrompt(newPrompt) {
	return (dispatch, getState) => {
		const campaignid = getState().NAID.selectedCampaignIndex;
		const newData = {
			prompt: newPrompt,
		};
		dispatch(updateCampaignFetchData(campaignid, newData));
	};
}

/**
 * Changes the campaign cost and charge data in the campaign data
 * TODO: Cleanup redundancy between this and UpdateCampaignCostsModal.js - and
 * maybe postBillingData too
 * @param {*} newSmsSurcharge The new surcharge for SMS
 * @param {*} newCampaignCosts The new charges for campaign
 * @param {*} spendLimit
 * @returns
 */
export function updateCampaignCosts(newSmsSurcharge, newCampaignCosts, spendLimit, agentHourCost) {
	return (dispatch, getState) => {
		const campaignId = getState().NAID.selectedCampaignIndex;

		const newData = {
			smsDollarsPerSegment: newSmsSurcharge,
			campaignSpendLimit: spendLimit,
			agentHoursCostPerHour: agentHourCost,
			campaignCharges: newCampaignCosts || undefined,
		};

		// parse currency values as floats
		[newData.agentHoursCostPerHour, newData.campaignSpendLimit, newData.smsDollarsPerSegment].map((value) => {
			if (value !== null && value !== undefined) {
				value = parseFloat(value);
			}
		});

		dispatch(
			postBillingData(
				campaignId,
				newData.campaignCharges,

				// send as string with trailing decimal places
				newData.agentHoursCostPerHour.toFixed(2),
				newData.campaignSpendLimit.toFixed(2),

				// accurate to the 10th of a cent
				newData.smsDollarsPerSegment.toFixed(3),
			),
		);
	};
}

/**
 * Update agent hour cost data for campaigns
 * @param {*} cost The cost per agent hour
 * @param {*} hours Override agent hours with this
 * @returns
 *
 * TODO: This doesn't appear to be used - delete it?
 */
export function updateAgentHours(cost, hours) {
	return (dispatch, getState) => {
		let newData = {};
		const campaignid = getState().NAID.selectedCampaignIndex;

		if (cost !== null && cost !== undefined) {
			newData.costperagenthour = cost;
		}

		if (hours !== null && hours !== undefined) {
			newData.totalagenthours = hours;
		}

		return dispatch(updateCampaignFetchData(campaignid, newData));
	};
}

/**
 * Moves campaign status from 'notyet' to 'prelaunch'
 * @returns
 */
export function requestCampaignLaunch() {
	return (dispatch, getState) => {
		const campaignid = getState().NAID.selectedCampaignIndex;
		let newData = {
			active: "prelaunch",
		};

		dispatch(updateCampaignFetchData(campaignid, newData)).then(() => {
			dispatch(updateCampaignStatus(campaignid, newData.active));
		});
	};
}

/**
 * Moves campaign status from 'prelaunch' to 'launched'
 * @returns
 */
export function launchCampaign() {
	return (dispatch, getState) => {
		const campaignid = getState().NAID.selectedCampaignIndex;
		let newData = {
			active: "active",
			startdate: "NOW",
		};

		dispatch(updateCampaignFetchData(campaignid, newData)).then(() => {
			dispatch(updateCampaignStatus(campaignid, newData.active));
		});
	};
}

/**
 * Moves campaign status from an active one to 'deactivated'
 * @returns
 */
export function closeCampaign() {
	return (dispatch, getState) => {
		const campaignid = getState().NAID.selectedCampaignIndex;
		let newData = {
			active: "deactivated",
			enddate: "NOW",
		};

		dispatch(updateCampaignFetchData(campaignid, newData)).then(() => {
			dispatch(updateCampaignStatus(campaignid, newData.active));
		});
	};
}

/**
 * Sets (and unsets) campaign 'archive' status
 *
 * This is very similar to closeCampaign above, but rather than add
 * more finicky conditional logic to /campaigns/:id, I'm using
 * a dedicated endpoint /campaigns/:id/status. (This endpoint
 * would be a good way to handle status transitions in the future.)
 */
export function setCampaignArchiveStatus(status) {
	return (dispatch, getState) => {
		const campaignid = getState().NAID.selectedCampaignIndex;
		return dispatch(
			fetchRequest("SET_CAMPAIGN_ARCHIVE_STATUS", "POST", `/campaigns/${campaignid}/status`, {
				status: status,
			}),
		).then(() => {
			dispatch(selectedCampaignFetchData(campaignid));
		});
	};
}

/**
 * Changes the value of RR adjustment in the campaign data
 * @param {bool} newValue The new value for the RR adjustment in quotas
 * @returns
 */
export function setResponseRateAdjustment(newValue) {
	return (dispatch, getState) => {
		const campaignid = getState().NAID.selectedCampaignIndex;
		let newData = {
			use_response_rate_adjustment: newValue,
		};

		dispatch(updateCampaignFetchData(campaignid, newData));
	};
}

/**
 * Set campaign quotas to use web completes instead of standard completes
 * @param {bool} useWebCompletes The new value for the RR adjustment in quotas
 */
export function setUseWebCompletes(useWebCompletes) {
	return (dispatch, getState) => {
		const campaignid = getState().NAID.selectedCampaignIndex;
		let newData = {
			use_web_completes: useWebCompletes,
		};

		dispatch(updateCampaignFetchData(campaignid, newData));
	};
}

export function updateLogins(newLogins, campaignid, append) {
	return (dispatch, getState) => {
		dispatch(
			fetchRequest("LOGINS", "POST", "/updateCampaignLogins", {
				campaignid: campaignid,
				logins: newLogins,
				append: append,
			}),
		);
	};
}

export function agentHoursPost(id) {
	return (dispatch, getState) => {
		const params = {
			campaignid: id || getState().NAID.selectedCampaignIndex,
		};

		dispatch(fetchRequest("AGENT_HOURS", "POST", "/retrieveuserhistory", params));
	};
}

export function retrieveQuotasData(campaignid) {
	return (dispatch) =>
		dispatch(
			fetchRequest("QUOTAS", "POST", "/retrieveQuotasData", {
				campaignid: campaignid,
			}),
		);
}

export function updateQuotasData(campaignid, data) {
	return (dispatch) =>
		dispatch(
			fetchRequest("Q", "POST", "/updateQuotasData", {
				campaignid: campaignid,
				data,
			}),
		);
}

export function clearQuotasData() {
	return (dispatch) =>
		dispatch({
			type: "CLEAR_QUOTAS_DATA",
		});
}

export function generateStrataTable(campaignid, fields) {
	return (dispatch, getState) => {
		const params = {
			campaignid: campaignid,
			fields: fields,
		};

		return dispatch(fetchRequest("QUOTAS", "POST", "/generateStrataTable", params)).then((response) => {
			if (response === undefined) {
				dispatch(
					addNotification({
						type: "SIMPLE_MESSAGE",
						severity: "ERROR",
						message: "Server failed to generate strata. Please contact support.",
						visible: true,
						fadeOut: false,
					}),
				);
			}
		});
	};
}

export function enterSandboxMode() {
	return (dispatch, getState) =>
		dispatch(
			fetchRequest("SANDBOX_ENTER", "POST", "/campaignEnterSandbox", {
				campaignid: getState().NAID.selectedCampaignIndex,
			}),
		).then((response) => {
			if (response) dispatch(updateCampaignStatus(getState().NAID.selectedCampaignIndex, "sandbox"));
		});
}

export function exitSandboxMode() {
	return (dispatch, getState) =>
		dispatch(
			fetchRequest("SANDBOX_EXIT", "POST", "/campaignExitSandbox", {
				campaignid: getState().NAID.selectedCampaignIndex,
			}),
		).then((response) => {
			if (response) dispatch(updateCampaignStatus(getState().NAID.selectedCampaignIndex, "notyet"));
		});
}

export function pauseCampaign(campaignid, subtwiliosid) {
	return (dispatch, getState) =>
		dispatch(
			fetchRequest("PAUSE", "POST", "/pauseCampaign", {
				campaignid: campaignid,
				twilioroom: subtwiliosid,
			}),
		).then((response) => {
			if (response) dispatch(updateCampaignStatus(campaignid, "paused"));
		});
}

export function resumeCampaign(campaignid) {
	return (dispatch, getState) =>
		dispatch(
			fetchRequest("RESUME", "POST", "/resumeCampaign", {
				campaignid: campaignid,
			}),
		).then((response) => {
			if (response) dispatch(updateCampaignStatus(campaignid, "active"));
		});
}

export function getRandomListEntries(howmany) {
	return (dispatch, getState) =>
		dispatch(
			fetchRequest("RANDOM_LIST_ENTRIES", "POST", "/getRandomListEntries", {
				campaignid: getState().NAID.selectedCampaignIndex,
				howmany: howmany,
			}),
		);
}

export function retrieveWarnings(campaignid) {
	return (dispatch) =>
		dispatch(
			fetchRequest("WARNINGS", "POST", "/retrieveWarnings", {
				campaignid: campaignid,
			}),
		);
}

export function updateTextSettings(campaignid, fields) {
	return (dispatch) =>
		dispatch(
			fetchRequest("TEXT_SETTINGS", "POST", "/updateTextSettings", {
				campaignid: campaignid,
				...fields,
			}),
		).then((response) => {
			if (response) {
				Object.keys(fields).forEach((key) => {
					if (fields[key] === undefined) {
						delete fields[key];
					}
				});
				dispatch({
					type: "UPDATE_CAMPAIGN_DATA_FIELDS",
					fields: fields,
				});
			}
			return response;
		});
}

/**
 * Sets the updated flag for the campaign data. If set to true it will trigger a reload of the selectedCampaignDtata
 * @param {bool} updated the bool updated flag
 * @returns
 */
export function setCampaignDataUpdated(updated) {
	return (dispatch) =>
		dispatch({
			type: SET_CAMPAIGN_DATA_UPDATED,
			updated,
		});
}

/**
 * Update the partials data via the updateCampaignData endpoint
 * @param {string} partialScriptID
 * @param {Array<number>} partialAnswerList
 * @returns {CallableFunction}
 */
export function updatePartialCompleteData(partialScriptID, partialAnswerList) {
	return (dispatch, getState) => {
		const campaignid = getState().NAID.selectedCampaignIndex;
		let newData = {
			partial_complete_script_id: partialScriptID,
			partial_complete_answer_list: partialAnswerList,
		};

		dispatch(updateCampaignFetchData(campaignid, newData));
	};
}

/**
 * Fetch the billing data for the campaign-level billing page
 * @param {number} campaignId
 * @returns {CallableFunction}
 */
export function getBillingData(campaignId) {
	return (dispatch) => {
		dispatch(
			fetchRequest("CAMPAIGN_BILLING", "GET", `/campaigns/${campaignId}/billingData`, {
				campaignId: campaignId,
			}),
		);
	};
}

/**
 * Send updated billing data for the campaign-level billing page
 * @param {number} campaignId
 * @param {number} agentHoursCostPerHour Unit: dollars (float)
 * @param {number} campaignSpendLimit    Unit: dollars (float)
 * @param {Array<{charge: number, description: string}>} campaignCharges Unit: dollars (int)
 * @param {number} smsDollarsPerSegment  Unit: dollars (float)
 * @returns {CallableFunction}
 */
export function postBillingData(
	campaignId,
	campaignCharges,
	agentHoursCostPerHour,
	campaignSpendLimit,
	smsDollarsPerSegment,
) {
	return (dispatch) => {
		dispatch(
			fetchRequest("CAMPAIGN_BILLING", "POST", `/campaigns/${campaignId}/billingData`, {
				campaignCharges: campaignCharges,
				agentHoursCostPerHour: agentHoursCostPerHour,
				campaignSpendLimit: campaignSpendLimit,
				smsDollarsPerSegment: smsDollarsPerSegment,
			}),
		);
	};
}

/**
 * GET generated results for the campaign as a file
 * @param {number} campaignId The id for the campaign
 * @returns {Promise}
 */
export function getGeneratedCampaignResultsFile(campaignId, howManyRecords) {
	return async function (dispatch) {
		const query = howManyRecords ? `?howMany=${howManyRecords}` : "";
		const url = `/campaigns/${campaignId}/results_generator${query}`;
		const data = await dispatch(
			fetchRequest("GENERATE_RESULTS_DATA", "GET", url)
		);

		if (!data) {
			console.error("No URL returned");
			return;
		}

		console.log("What did the endpoint return?", data);

		window.open(data.url);
	};
}

/**
 * GET previously generated simulated results file data
 * TODO: Needs reducer
 * @param {number} campaignId The id for the campaign
 * @returns {Promise}
 */
export function getPreviouslyGeneratedFileInfo(campaignId) {
	return function (dispatch) {
		return dispatch(
			fetchRequest(
				"GET_PREVIOUS_GENERATED_RESULTS",
				"GET",
				`/campaigns/${campaignId}/results_generator/lastExported`
			)
		);
	};
}

/**
 * GET generated results for the campaign as a file
 * @param {number} campaignId The id for the campaign
 * @returns {Promise}
 */
export function getDownloadPreviouslyGeneratedFileInfo(campaignId, filename) {
	return function (dispatch) {
		return dispatch(
			fetchRequest(
				"GET_PREVIOUS_RESULT_URL",
				"GET",
				`/campaigns/${campaignId}/results_generator/${filename}`
			)
		).then((data) => {
			if (!data) {
				console.error("No URL returned");
				return;
			}

			console.log("What did the endpoint return?", data);

			window.open(data.url);
		});
	};
}
