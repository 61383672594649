import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import NAID from "./NAID";
import selectedCampaign from "../redux/SelectedCampaign/reducer";
import auth from "../redux/Auth/reducer";
import userData from "../redux/Users/reducer";
import sidebar from "./sidebar";
import organizations from "./organizations";
import billing from "./billing";
import vitals from "./vitals";
import panel from "../redux/Panel/reducer";
import globalNotifications from "../redux/GlobalNotifications/reducer";
import agentStatus from "../redux/Agents/reducer";
import recipients from "../redux/Recipients/reducer";
import text_to_web from "../redux/TextToWeb/reducer";
import analytics from "../redux/Analytics/reducer";
import bandwidth from "../redux/Bandwidth/reducer";
import optoutList from "../redux/OptoutList/reducer";
import mediaReducer from "../redux/Media/reducer";
import quotas from "../redux/Quotas/reducer";
import navigationReducer from "../redux/Navigation/reducer";
import projectionsReducer from "../redux/Projections/reducer";

const createRootReducer = (history) =>
	combineReducers({
		router: connectRouter(history),
		NAID,
		selectedCampaign,
		auth,
		userData,
		sidebar,
		analytics,
		organizations,
		billing,
		vitals,
		panel,
		globalNotifications,
		agents: agentStatus,
		recipients,
		text_to_web,
		bandwidth,
		optoutList,
		media: mediaReducer,
		quotas,
		navigation: navigationReducer,
		projections: projectionsReducer,
	});

export default createRootReducer;
