import React from "react";
import { ExistingExportLink } from "./ExistingExportLink";
import List from "../List";

const EXISTING_EXPORTS_HEADERS = [
	{
		header: "File",
		title: "File",
		accessor: "last_results_filename",
	},
	{
		header: "Created On",
		title: "Export Created On",
		accessor: "last_results_timestamp",
	},
	{
		header: "",
		title: "Download",
		accessor: "link",
	},
];

/**
 * Render a table of the existing fileExports for results download
 * @param {Object} props
 * @param {Array<{last_results_filename: string, last_results_timestamp: Date, link: string}>} [props.fileExports=[]]
 * @param {function} props.downloadCallback Callback function for downloading the file
 * @param {number} props.selectedCampaignId
 * @returns
 */
export function ExistingExportTable({
	fileExports = [],
	downloadCallback,
	selectedCampaignId,
}) {
	if (fileExports.length == 0) {
		return null;
	}

	return (
		<div>
			Existing exports
			<br />
			<List
				headers={EXISTING_EXPORTS_HEADERS}
				list={fileExports.map((item, i) => {
					return {
						...item,
						link: (
							<ExistingExportLink
								callback={() =>
									downloadCallback(
										selectedCampaignId,
										item.last_results_filename
									)
								}
							/>
						),
					};
				})}
			/>
		</div>
	);
}
