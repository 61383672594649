import React, { Component } from "react";

import Modal from "react-modal";

export default class ClipboardFailFallbackModal extends Component {
	render() {
		const { text, isOpen, closeModal } = this.props;
		return (
			<Modal isOpen={isOpen} className={`modal ${isOpen ? " is-active" : ""}`}>
				<div className="modal-background" onClick={closeModal} />
				<div className="modal-card">
					<header className="modal-card-head">Your clipboard was not accessible</header>
					<section className="modal-card-body">
						<pre className="">{text}</pre>
					</section>
					<footer className="modal-card-foot">
						<button className="button is-danger" onClick={closeModal}>
							Close
						</button>
					</footer>
				</div>
			</Modal>
		);
	}
}
