import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	getGeneratedCampaignResultsFile,
	getPreviouslyGeneratedFileInfo,
	getDownloadPreviouslyGeneratedFileInfo,
} from "../redux/SelectedCampaign/actions";
import ConfirmActionModal from "./modals/ConfirmActionModal";
import { ExistingExportTable } from "./CampaignResults/ExistingExportTable";
import ModalContainer, {
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "./modals/ModalContainer";
import { LoaderAndErrorPrompt } from "./higher-order/ComponentWithLoadingAndError";

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Wrapper for the simulated campaign results button and modal.
 * - Loads existing simulated files.
 * - Processes props for SimulatedResultsModal
 * @param {object} props
 * @param {number} props.selectedCampaignId
 * @param {function} props.getGeneratedCampaignResultsFileCallback
 * @param {function} props.getDownloadPreviouslyGeneratedFileInfoCallback
 * @param {function} props.getPreviouslyGeneratedFileInfoCallback
 * @param {{loading: boolean, error: boolean, data: null | object}} props.getPreviousSimulatedResultsFile
 * @param {{loading: boolean, error: boolean, data: null | object}} props.generateAndDownloadSimulatedResultsFile
 * @param {{loading: boolean, error: boolean, data: null | object}} props.getPreviousResultURL
 * @returns {JSX.Element}
 */
function SimulatedCampaignResultsWrapper({
	selectedCampaignId,
	getGeneratedCampaignResultsFileCallback,
	getDownloadPreviouslyGeneratedFileInfoCallback,
	getPreviouslyGeneratedFileInfoCallback,

	// Redux data on actions
	getPreviousSimulatedResultsFile,
	generateAndDownloadSimulatedResultsFile,
	getPreviousResultURL,
}) {
	const [prevCampaignId, setPrevCampaignId] = useState(null);

	const fileExports = [];
	let existingFilesComponent = null;
	if (getPreviousSimulatedResultsFile.data) {
		fileExports.push(getPreviousSimulatedResultsFile.data);

		existingFilesComponent = (
			<LoaderAndErrorPrompt {...getPreviousSimulatedResultsFile}>
				<ExistingExportTable
					fileExports={fileExports}
					selectedCampaignId={selectedCampaignId}
					downloadCallback={
						getDownloadPreviouslyGeneratedFileInfoCallback
					}
				/>
			</LoaderAndErrorPrompt>
		);
	}

	useEffect(() => {
		if (
			!getPreviousSimulatedResultsFile.loading &&
			(fileExports.length === 0 || prevCampaignId !== selectedCampaignId)
		) {
			getPreviouslyGeneratedFileInfoCallback(selectedCampaignId);
			if (prevCampaignId !== selectedCampaignId) {
				setPrevCampaignId(selectedCampaignId);
			}
		}
	}, []); // Empty dependency array ensures this runs only once

	return (
		<SimulatedResultsModal
			selectedCampaignId={selectedCampaignId}
			fileExports={fileExports}
			loading={
				generateAndDownloadSimulatedResultsFile.loading ||
				getPreviousResultURL.loading
			}
			getGeneratedCampaignResultsFileCallback={
				getGeneratedCampaignResultsFileCallback
			}
			existingFilesComponent={existingFilesComponent}
		/>
	);
}

/**
 * Modal for generating and downloading Simulated Campaign Results
 * @param {object} props
 * @param {number} props.selectedCampaignId
 * @param {function} props.getGeneratedCampaignResultsFileCallback
 * @param {boolean} [props.loading=false]
 * @param {JSX.Element | null} [props.existingFilesComponent=null]
 * @returns {JSX.Element}
 */
function SimulatedResultsModal({
	selectedCampaignId,
	getGeneratedCampaignResultsFileCallback,
	loading = false,
	existingFilesComponent = null,
}) {
	const loadingButtonClass = `button is-success ${loading && "is-loading"}`;
	const [howMany, setHowMany] = useState(null);

	function updateHowManyInput(e) {
		setHowMany(e.target.value);
	}

	return (
		<ModalContainer
			name={
				<span>
					Generate Simulated Survey Results{" "}
					<FontAwesomeIcon icon={faDownload} />
				</span>
			}
			customButtonClass="button is-info is-fullwidth"
		>
			{(modalState) => (
				<React.Fragment>
					<ModalHeader>Generate Simulated Survey Results</ModalHeader>
					<ModalBody>
						<div>
							Generate a survey results file based on the script
							and recipient list of this campaign.
							{existingFilesComponent}
							<br />
							<div className="field">
								<label className="label">
									How many records do you want to generate? (
									Defaults to 10000 or recipient count )
								</label>
								<input
									className="input"
									type="number"
									step={1}
									name="howMany"
									placeholder={10000}
									onChange={updateHowManyInput}
								/>
							</div>
						</div>
					</ModalBody>
					<ModalFooter {...modalState}>
						<a
							className={loadingButtonClass}
							onClick={async () => {
								await getGeneratedCampaignResultsFileCallback(
									selectedCampaignId,
									howMany
								);
								modalState.toggleOpen();
							}}
						>
							Generate Results
						</a>
					</ModalFooter>
				</React.Fragment>
			)}
		</ModalContainer>
	);
}

function mapStateToProps(state) {
	const {
		getPreviousSimulatedResultsFile,
		generateAndDownloadSimulatedResultsFile,
		getPreviousResultURL,
	} = state.selectedCampaign;
	return {
		getPreviousSimulatedResultsFile,
		generateAndDownloadSimulatedResultsFile,
		getPreviousResultURL,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			getGeneratedCampaignResultsFileCallback: (
				campaignid,
				howManyRecords
			) => getGeneratedCampaignResultsFile(campaignid, howManyRecords),
			getPreviouslyGeneratedFileInfoCallback: (campaignid) =>
				getPreviouslyGeneratedFileInfo(campaignid),
			getDownloadPreviouslyGeneratedFileInfoCallback: (
				campaignid,
				filename
			) => getDownloadPreviouslyGeneratedFileInfo(campaignid, filename),
		},
		dispatch
	);
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SimulatedCampaignResultsWrapper);
