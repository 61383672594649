import {
	SELECTED_CAMPAIGN_DATA_FETCH_SUCCESS,
	SELECTED_CAMPAIGN_DATA_HAS_ERROR,
	SELECTED_CAMPAIGN_DATA_IS_LOADING,
	UPDATE_CAMPAIGN_DATA_POST_SUCCESS,
	UPDATE_CAMPAIGN_DATA_POST_HAS_ERROR,
	UPDATE_CAMPAIGN_DATA_POST_IS_LOADING,
	ADD_CAMPAIGN_FETCH_SUCCESS,
	SET_CAMPAIGN_DATA_UPDATED,
	SELECTED_CAMPAIGN_DATA_CLEAR,
} from "../../redux/SelectedCampaign/actions";
import { loadStateReducer } from "../../util/reusableReducers";

import { combineReducers } from "redux";
import reduceReducers from "reduce-reducers";

const initialState = {
	loading: false,
	error: false,
	updated: false,
	message: "",
};

function mainReducer(state = initialState, action) {
	switch (action.type) {
		case "CAMPAIGN_BILLING_POST_SUCCESS":
			return {
				...state,
				selectedCampaignData: {
					...state.selectedCampaignData,
					// Convert from dollars to cents
					spendlimit: action.data.campaignSpendLimit * 100,
				},
			};
		case SELECTED_CAMPAIGN_DATA_IS_LOADING:
			return {
				...state,
				loading: action.isLoading,
			};
		case SELECTED_CAMPAIGN_DATA_HAS_ERROR:
			return {
				...state,
				error: action.hasError,
				loading: false,
			};
		case SELECTED_CAMPAIGN_DATA_FETCH_SUCCESS:
			return {
				...state,
				updated: false,
				loading: false,
				selectedCampaignData: action.data,
			};
		case SELECTED_CAMPAIGN_DATA_CLEAR:
			return {
				...state,
				updated: false,
				loading: false,
				selectedCampaignData: null,
			};
		case UPDATE_CAMPAIGN_DATA_POST_SUCCESS:
			return {
				...state,
				updated: true,
			};
		case "AGENT_HOURS_POST_SUCCESS":
			return {
				...state,
				agentHours: {
					...action.data.data,
				},
			};
		case "SANDBOX_ENTER_POST_SUCCESS":
			return {
				...state,
				selectedCampaignData: {
					...state.selectedCampaignData,
					active: "sandbox",
				},
			};
		case "SANDBOX_EXIT_POST_SUCCESS":
			return {
				...state,
				selectedCampaignData: {
					...state.selectedCampaignData,
					active: "notyet",
				},
			};
		case "PAUSE_POST_SUCCESS":
			return {
				...state,
				selectedCampaignData: {
					...state.selectedCampaignData,
					active: "paused",
				},
			};
		case "RESUME_POST_SUCCESS":
			return {
				...state,
				selectedCampaignData: {
					...state.selectedCampaignData,
					active: "active",
				},
			};

		case "RANDOM_LIST_ENTRIES_POST_SUCCESS":
			return {
				...state,
				selectedCampaignData: {
					...state.selectedCampaignData,
					list: action.data.data,
				},
			};
		case "UPDATE_CAMPAIGN_DATA_FIELDS":
			return {
				...state,
				selectedCampaignData: {
					...state.selectedCampaignData,
					...action.fields,
				},
			};
		case SET_CAMPAIGN_DATA_UPDATED:
			return {
				...state,
				updated: action.updated,
			};
		case "CLEAR_DATA":
			return initialState;
		case ADD_CAMPAIGN_FETCH_SUCCESS:
		default:
			return state;
	}
}

const billingLoadStateReducer = loadStateReducer({
	CAMPAIGN_BILLING_IS_LOADING: "loading",
	CAMPAIGN_BILLING_HAS_ERROR: "error",
	CAMPAIGN_BILLING_POST_SUCCESS: "success",
});

const billingReducer = (state, action) => {
	switch (action.type) {
		case "CAMPAIGN_BILLING_GET_SUCCESS":
			return {
				...state,
				loading: false,
				data: action.data,
			};
		default:
			return state;
	}
};

const agentHoursReducer = loadStateReducer({
	AGENT_HOURS_LOADING: "loading",
	AGENT_HOURS_ERROR: "error",
});

const quotasReducer = loadStateReducer({
	QUOTAS_IS_LOADING: "loading",
	QUOTAS_HAS_ERROR: "error",
	QUOTAS_POST_SUCCESS: "success",
});

const quotasReducerUtil = (state, action) => {
	switch (action.type) {
		case "CLEAR_QUOTAS_DATA":
			return {
				...state,
				data: undefined,
				error: undefined,
			};
		default:
			return state;
	}
};

const sandboxReducer = loadStateReducer({
	SANDBOX_ENTER_IS_LOADING: "loading",
	SANDBOX_ENTER_HAS_ERROR: "error",
	SANDBOX_EXIT_IS_LOADING: "loading",
	SANDBOX_EXIT_HAS_ERROR: "error",
});

const warnings = loadStateReducer({
	WARNINGS_IS_LOADING: "loading",
	WARNINGS_HAS_ERROR: "error",
	WARNINGS_POST_SUCCESS: "success",
});

const updateCampaignData = loadStateReducer({
	UPDATE_CAMPAIGN_DATA_IS_LOADING: "loading",
	UPDATE_CAMPAIGN_DATA_HAS_ERROR: "error",
	UPDATE_CAMPAIGN_DATA_POST_SUCCESS: "success",
});

// Simulated Data operations
const getPreviousSimulatedResultsFile = loadStateReducer({
	GET_PREVIOUS_GENERATED_RESULTS_IS_LOADING: "loading",
	GET_PREVIOUS_GENERATED_RESULTS_HAS_ERROR: "error",
	GET_PREVIOUS_GENERATED_RESULTS_GET_SUCCESS: "success",
});

const generateAndDownloadSimulatedResultsFile = loadStateReducer({
	GENERATE_RESULTS_DATA_IS_LOADING: "loading",
	GENERATE_RESULTS_DATA_HAS_ERROR: "error",
	GENERATE_RESULTS_DATA_GET_SUCCESS: "success",
});

const getPreviousResultURL = loadStateReducer({
	GET_PREVIOUS_RESULT_URL_IS_LOADING: "loading",
	GET_PREVIOUS_RESULT_URL_HAS_ERROR: "error",
	GET_PREVIOUS_RESULT_URL_GET_SUCCESS: "success",
});

export default combineReducers({
	billingData: reduceReducers(billingLoadStateReducer, billingReducer),
	selectedCampaign: mainReducer,
	agentHours: agentHoursReducer,
	warnings: warnings,
	quotas: reduceReducers(quotasReducer, quotasReducerUtil),
	sandbox: sandboxReducer,
	updateCampaignData,
	getPreviousSimulatedResultsFile,
	generateAndDownloadSimulatedResultsFile,
	getPreviousResultURL,
});
