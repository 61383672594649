const PROJECTIONS_PREFIX = "CAMPAIGN_PROJECTIONS";

// export const CAMPAIGN_PROJECTIONS_IS_LOADING = `${PROJECTIONS_PREFIX}_IS_LOADING`;
// export const CAMPAIGN_PROJECTIONS_HAS_ERROR = `${PROJECTIONS_PREFIX}_HAS_ERROR`;
// export const CAMPAIGN_PROJECTIONS_POST_SUCCESS = `${PROJECTIONS_PREFIX}_POST_SUCCESS`;
// export const CAMPAIGN_PROJECTIONS_GET_SUCCESS = `${PROJECTIONS_PREFIX}_GET_SUCCESS`;

export const GLOBAL_PROJECTIONS_PREFIX = `${PROJECTIONS_PREFIX}_GLOBAL`;
export const GLOBAL_PROJECTIONS_IS_LOADING = `${GLOBAL_PROJECTIONS_PREFIX}_IS_LOADING`;
export const GLOBAL_PROJECTIONS_HAS_ERROR = `${GLOBAL_PROJECTIONS_PREFIX}_HAS_ERROR`;
export const GLOBAL_PROJECTIONS_POST_SUCCESS = `${GLOBAL_PROJECTIONS_PREFIX}_POST_SUCCESS`;
export const GLOBAL_PROJECTIONS_GET_SUCCESS = `${GLOBAL_PROJECTIONS_PREFIX}_GET_SUCCESS`;

export const CAMPAIGN_PROJECTIONS_BY_ID_PREFIX = `${PROJECTIONS_PREFIX}_BY_ID`;
export const CAMPAIGN_PROJECTIONS_BY_ID_CLEAR = `${CAMPAIGN_PROJECTIONS_BY_ID_PREFIX}_CLEAR`;
export const CAMPAIGN_PROJECTIONS_BY_ID_HAS_ERROR = `${CAMPAIGN_PROJECTIONS_BY_ID_PREFIX}_HAS_ERROR`;
export const CAMPAIGN_PROJECTIONS_BY_ID_IS_LOADING = `${CAMPAIGN_PROJECTIONS_BY_ID_PREFIX}_IS_LOADING`;
export const CAMPAIGN_PROJECTIONS_BY_ID_GET_SUCCESS = `${CAMPAIGN_PROJECTIONS_BY_ID_PREFIX}_GET_SUCCESS`;
export const CAMPAIGN_PROJECTIONS_BY_ID_POST_SUCCESS = `${CAMPAIGN_PROJECTIONS_BY_ID_PREFIX}_POST_SUCCESS`;

export const CAMPAIGN_PROJECTIONS_UPDATE_PREFIX = `${PROJECTIONS_PREFIX}_UPDATE`;
export const CAMPAIGN_PROJECTIONS_UPDATE_SUCCESS = `${CAMPAIGN_PROJECTIONS_UPDATE_PREFIX}_POST_SUCCESS`;
export const CAMPAIGN_PROJECTIONS_UPDATE_IS_LOADING = `${CAMPAIGN_PROJECTIONS_UPDATE_PREFIX}_IS_LOADING`;
export const CAMPAIGN_PROJECTIONS_UPDATE_ERROR = `${CAMPAIGN_PROJECTIONS_UPDATE_PREFIX}_HAS_ERROR`;
